import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Stack, Typography } from '@mui/material';
import { ErrorOutline, CheckCircle } from '@mui/icons-material'; // Import icons
import { dialogContentStyles } from '../../styles/dialog';

interface ErrorDialogProps {
  message: string;
  isSuccess: boolean;
  open: boolean;
  close: () => void;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({ message, isSuccess, open, close }) => {
  // Dynamically determine header color based on isSuccess prop
  const headerColor = isSuccess ? '#4CAF50' : '#cc0000';

  // Merge the dynamic header color with the rest of the header styles
  const dynamicHeaderStyles = {
    background: headerColor,
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle sx={dynamicHeaderStyles}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <span>{isSuccess ? "Success" : "Error"}</span>
          {isSuccess ? (
            <CheckCircle sx={{ fontSize: 24 }} />
          ) : (
            <ErrorOutline sx={{ fontSize: 24 }} />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ ...dialogContentStyles, pb: 0 }}>
        <p>{message}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          sx={{
            backgroundColor: headerColor,
            color: "white",
            "&:hover": { background: headerColor, cursor: "pointer" },
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const keyframes = () => ({
  '@keyframes ani': {
    from: {
      backgroundPosition: '0 0',
    },
    to: {
      backgroundPosition: '400% 0',
    },
  },
});

const GlobalStyle = styled('div')(keyframes);

const RootContainer = styled('div')({
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
});

const Heading = styled(Typography)({
  fontSize: '30vw',
  fontWeight: 700,
  fontFamily: 'sans-serif',
  position: 'relative',
  background: 'white',
  color: 'black',
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    WebkitFilter: 'blur(20px)',
    filter: 'blur(20px)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    mixBlendMode: 'screen',
    backgroundImage: 'repeating-linear-gradient(-45deg, transparent, transparent 1em, #9E2053 1em, orange 50%), repeating-linear-gradient(45deg, #111626, #111626 1em, pink 1em, #571B3D 50%)',
    backgroundSize: '3em 3em, 2em 2em',
    animation: 'ani 8s linear infinite alternate',
  },
});

const SubHeading = styled(Typography)({
  margin: '20px 0',
  textAlign: 'center',
});

export const PageNotFound: React.FC = () => {
  const handleGoToHome = () => {
    window.location.href = './';
  };

  return (
    <>
      <GlobalStyle />
      <RootContainer>
        <Heading variant="h1">404</Heading>
        <Typography variant="h4" gutterBottom>Whoops!</Typography>
        <SubHeading variant="body1">It seems like we couldn't find the page you were looking for.</SubHeading>
        <Button variant="contained" sx={{mb:2}} onClick={handleGoToHome}>Go to Home</Button>
      </RootContainer>
    </>
  );
}



import { FC } from "react";
import { Box } from "@mui/material";

interface IBannerProps {
  banner: string;
}

export const Banner: FC<IBannerProps> = ({ banner }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        //height: "100vh",
        width: "100vw",
        //backgroundColor: "black",
        textAlign: "center",
        paddingX: 0,
        paddingTop: {xs: '56px', sm: '0'},
        //color: "white",
      }}
    >
      <Box display={'contents'}>
        <Box
          component="img"
          src={banner}
          alt="Loading"
          sx={{
            width: { xs: "100%", sm: "100%" },
            height: { xs: "auto", sm: "auto" },
          }}
        />
      </Box>
    </Box>
  );
};

import { Button } from "@mui/material";
import { buttonStyles } from "../../styles/button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FC, useState } from "react";
import { ReceiptDialog } from "./dialog";


interface ReceiptProps {
  payment: any;
  user: any;
  index: number;
  schemeRef: number;
}

export const Receipt: FC<ReceiptProps> = ({ payment, user, index, schemeRef }) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  return (
    <>
      <div>
        <Button
          sx={{
            ...buttonStyles,
            width: "100%",
            textTransform: "none",
            gap: 1,
            background: 'none',
            color: '#4f0336',
            '&:hover': { background: 'none' },
          }}
          onClick={() => setOpenDialog(true)}
        >
          <FileDownloadIcon />
        </Button>
        <ReceiptDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          user={user}
          payment={payment}
          index={index}
          schemeRef={schemeRef}
        />
      </div>
    </>
  );
};


import { gql } from "@apollo/client";

export const GET_COMMODITY_PRICE = gql`
    query GetCommodityPrice {
        getCommodityPrice {
            status
            currency
            unit
            metals {
                gold
                sovereign_gold
                ounce_gold
                silver
            }
            timestamp
        }
    }
`;
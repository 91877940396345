import { FC, useState } from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  ImageList,
  ImageListItem,
  IconButton,
} from "@mui/material";
import {
  dialogActionsStyles,
  dialogCancelActionStyles,
  dialogContentStyles,
  dialogHeaderStyles,
  dialogSubmitActionStyles,
} from "../../styles/dialog";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_INFO } from "../../gql/user-info";
import { useAuth } from "../../contexts/AuthContext";
import { Spinner } from "../spinner";
import { storage } from "../../config/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import { ErrorDialog } from "../errorDialog";

interface ProfileSettingProps {
  open: boolean;
  onClose: () => void;
}

export const ProfileSetting: FC<ProfileSettingProps> = ({
  open = false,
  onClose,
}) => {
  const { currentUser } = useAuth();
  const userInfo = currentUser?.profileInfo?.userInfo;
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false); // State for error dialog
  const [errorMessage, setErrorMessage] = useState(""); // State to hold error message
  const [firstName, setFirstName] = useState<string>(userInfo?.firstName);
  const [lastName, setLastName] = useState<string>(userInfo?.lastName);
  const [emergencyPhone, setEmergencyPhone] = useState<string>(
    userInfo?.emergencyPhoneNo
  );
  const [dateOfBirth, setDateOfBirth] = useState<string>(
    userInfo?.dateOfBirth || "Date of Birth"
  );
  const [addressProofOne, setAddressProofOne] = useState<string>(
    userInfo?.addressProofOne
  );
  const [addressProofTwo, setAddressProofTwo] = useState<string>(
    userInfo?.addressProofTwo
  );
  const [images, setImages] = useState<FileList>();
  const [addressImages, setAddressImages] = useState<any>([]);
  const [updateProfileMutation] = useMutation(UPDATE_USER_INFO, {
    refetchQueries: ["GetUserByPhone"],
  });
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const [profilePic, setProfilePic] = useState<File | null>(userInfo?.profilePic);

  useEffect(() => {
    const imageArr = [];
    if (userInfo?.addressProofOne) {
      imageArr.push({
        title: "AddressProoof1",
        img: userInfo?.addressProofOne,
      });
    }
    if (userInfo?.addressProofTwo) {
      imageArr.push({
        title: "AddressProoof2",
        img: userInfo?.addressProofTwo,
      });
    }
    setAddressImages(imageArr);
  }, [userInfo]);
  const resetForm = () => {
    setFirstName(userInfo?.firstName);
    setLastName(userInfo?.lastName);
    setEmergencyPhone(userInfo?.emergencyPhoneNo);
    setDateOfBirth(userInfo?.dateOfBirth || "Date of Birth");
    setAddressProofOne(userInfo?.addressProofOne);
    setAddressProofTwo(userInfo?.addressProofTwo);
    setImages(undefined);
    setProfilePic(null);
  };

  const isKycDone = currentUser?.profileInfo?.userInfo?.isKycDone;

  const touched =
    firstName != userInfo?.firstName ||
    lastName != userInfo?.lastName ||
    emergencyPhone != userInfo?.emergencyPhoneNo ||
    (dateOfBirth != userInfo?.dateOfBirth && dateOfBirth != "Date of Birth") ||
    isUploaded;

  const isDisabled =
    !firstName ||
    !lastName ||
    !emergencyPhone ||
    emergencyPhone?.length < 10 ||
    !dateOfBirth ||
    dateOfBirth === "Date of Birth" ||
    !touched ||
    uploading ||
    !isUploaded ||
    (images && images.length > 0 ? !isUploaded : false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setResult({
      isSubmitted: false,
      message: "",
    });
    event.preventDefault();
    setLoading(true);
    if (profilePic) {
      const path = `profilePics/${currentUser?.phoneNumber}`;
      const storageRef = ref(storage, path);

      const uploadTask = uploadBytesResumable(storageRef, profilePic);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading...");
        },
        (error) => {
          setErrorMessage("Error while uploading profile pic. Please try again after sometime.");
          setOpenErrorDialog(true); // Open error dialog
          console.log(error);
          setLoading(false);
          return false;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            updateProfile(downloadURL);
          })
        })
    } else {
      updateProfile();
    }
  };

  const updateProfile = (profilePic?: string) => {
    if (!emergencyPhone?.includes("+")) {
      setResult({
        isSubmitted: false,
        message: "Please provide emergency phone with extension.",
      });
      setLoading(false);
      return;
    }
    if (currentUser?.phoneNumber?.includes(emergencyPhone)) {
      setResult({
        isSubmitted: false,
        message:
          "Emergency phone number cannot be same as your phone number. Please provide a different contact number.",
      });
      setLoading(false);
      return;
    }
    updateProfileMutation({
      variables: {
        userInfoInput: {
          userId: currentUser?.profileInfo?.id,
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: dateOfBirth,
          emergencyPhoneNo: emergencyPhone,
          addressProofOne: addressProofOne,
          addressProofTwo: addressProofTwo,
          profilePic: profilePic,
        },
      },
      onCompleted: (data: any) => {
        if (data?.updateUserInfo) {
          console.log("Profile Updated");
          setLoading(false);
          onClose();
          window.location.reload();
        }
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        setResult({
          isSubmitted: false,
          message:
            "Error while processing this request, please try again after sometime.",
        });
      },
    });
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      if (files.length > 2) {
        setErrorMessage("You can not upload more than two documents.");
        setOpenErrorDialog(true);
        return;
      } else {
        setImages(files);
      }
    }
  };

  const uploadImages = () => {
    setUploading(true);
    setIsUploaded(false);
    if (images && images.length > 0) {
      const path = `proofs/${currentUser?.phoneNumber}-AddressProofOne`;
      const storageRef = ref(storage, path);

      const uploadTask = uploadBytesResumable(storageRef, images[0]);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading...");
        },
        (error) => {
          setErrorMessage("Error while uploading image. Please try again after sometime.");
          setOpenErrorDialog(true); // Open error dialog
          console.log(error);
          setUploading(false);
          return false;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            setAddressProofOne(downloadURL);
            setIsUploaded(true);
            if (images && images.length > 1) {
              const path = `proofs/${currentUser?.phoneNumber}-AddressProofTwo`;
              const storageRef = ref(storage, path);

              const uploadTask = uploadBytesResumable(storageRef, images[1]);

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  console.log("Uploading");
                },
                (error) => {
                  setErrorMessage("Error while uploading image. Please try again after sometime.");
                  setOpenErrorDialog(true); // Open error dialog
                  console.log(error);
                  setUploading(false);
                  return false;
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                      setAddressProofTwo(downloadURL);
                      setUploading(false);
                      setIsUploaded(true);
                    }
                  );
                }
              );
            } else {
              setUploading(false);
              setIsUploaded(true);
            }
          });
        }
      );
    }
  };

  const handleProfilePicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newImage = event.target?.files;
    if (newImage && newImage.length > 0) {
      setProfilePic(newImage[0]);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={dialogHeaderStyles}>Complete your profile</DialogTitle>
        <Box>
          <DialogContent sx={dialogContentStyles}>
            {result && result.message && (
              <Typography
                color={result.isSubmitted ? "#4F0336" : "red"}
                mb={2}
                fontSize={12}
              >
                {result.message}
              </Typography>
            )}
            <form onSubmit={handleSubmit}>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 3, justifyContent: 'center' }}>
                <Box>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    hidden
                    onChange={handleProfilePicChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Avatar
                      sx={{
                        bgcolor: "#4f0336",
                        width: 80,
                        height: 80,
                        cursor: "pointer",
                        border: "3px solid #4F0336",
                      }}
                      alt="Add Profile Pic"
                      src={profilePic ? (typeof profilePic === 'string' ? profilePic : URL.createObjectURL(profilePic)) : "/add-user.svg"}
                    ></Avatar>
                  </label>
                </Box>
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  fullWidth
                  disabled={isKycDone}
                  required
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  fullWidth
                  disabled={isKycDone}
                  required
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                <TextField
                  type="tel"
                  variant="outlined"
                  color="secondary"
                  label="Phone No"
                  value={currentUser?.phoneNumber}
                  fullWidth
                  disabled
                  inputProps={{ maxLength: 13, minLength: 10 }}
                  required
                />
                <TextField
                  type="tel"
                  variant="outlined"
                  color="secondary"
                  label="Emergency Phone No"
                  onChange={(e) => setEmergencyPhone(e.target.value)}
                  value={emergencyPhone}
                  fullWidth
                  inputProps={{ maxLength: 13, minLength: 10 }}
                  required
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                <TextField
                  type="date"
                  variant="outlined"
                  color="secondary"
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  value={dateOfBirth}
                  fullWidth
                  disabled={isKycDone}
                  label="Date of Birth"
                  required
                />
              </Stack>

              {addressImages && addressImages.length > 0 ? (
                <>
                  <label style={{ fontSize: 12 }}>Address Proof(s)</label>
                  <ImageList sx={{ width: "100%", height: 200, mt: 1 }} cols={1}>
                    {addressImages?.map((item: any, index: number) => (
                      <ImageListItem key={index}>
                        <img
                          src={item.img}
                          alt={item.title}
                          loading="lazy"
                          height={100}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                  <span style={{ color: "#4F0336", fontSize: 12 }}>
                    *To update address proof(s), please contact us.
                  </span>
                  {/* <Button
              // disabled={
              //   uploading
              // }
              size="medium"
              sx={dialogSubmitActionStyles}
              onClick={() => setAddressImages([])}
            >
              <span>Change</span>
            </Button> */}
                </>
              ) : (
                // <Stack
                //   spacing={2}
                //   sx={{ marginBottom: 2 }}
                //   flexDirection={"row"}
                //   alignItems={"center"}
                // >
                //   <label style={{ fontSize: 12 }}>Address Proof(s)</label>
                //   {addressProofOne && (
                //     <a
                //       href={addressProofOne}
                //       target="_blank"
                //       style={{
                //         color: "#4F0336",
                //         marginTop: 0,
                //         paddingLeft: 8,
                //         fontSize: 12,
                //       }}
                //     >
                //       View
                //     </a>
                //   )}
                //   {addressProofTwo && (
                //     <a
                //       href={addressProofTwo}
                //       target="_blank"
                //       style={{
                //         color: "#4F0336",
                //         marginTop: 0,
                //         paddingLeft: 8,
                //         fontSize: 12,
                //       }}
                //     >
                //       View
                //     </a>
                //   )}
                // </Stack>
                <>
                  {/* (
              {images && images.length > 0 ? (
                <ImageList sx={{ width: '100%', height: 200 }} cols={1}>
                {Array.from(images).map((item: any, index: number) => (
                  <ImageListItem key={index}>
                    <img
                      src={URL.createObjectURL(item)}
                      alt='Address Proof'
                      loading="lazy"
                      height={100}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              ) :  */}

                  <Stack spacing={1} sx={{ marginBottom: 2 }}>
                    <label>Upload Address Proof(s)(Max 2)</label>
                    <input
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={handleImageChange}
                      hidden
                      multiple
                    />
                    <label htmlFor="image-upload">
                      <Box
                        style={{ cursor: "pointer" }}
                        component="img"
                        src="/upload.svg"
                        alt="Upload"
                        height="80px"
                      />
                    </label>
                    <span>
                      {images
                        ? `${images[0]?.name || ""}, ${images[1]?.name || ""}`
                        : "No file chosen"}
                    </span>

                    <Button
                      disabled={!images || images.length === 0 || uploading}
                      size="medium"
                      sx={dialogSubmitActionStyles}
                      onClick={() => {
                        setImages(undefined);
                        setAddressProofOne("");
                        setAddressProofTwo("");
                        setIsUploaded(false);
                      }}
                    >
                      <span>Remove</span>
                    </Button>
                    <Button
                      disabled={
                        isUploaded || !images || images.length === 0 || uploading
                      }
                      size="medium"
                      sx={dialogSubmitActionStyles}
                      onClick={uploadImages}
                    >
                      {isUploaded ? (
                        <>
                          <span style={{ color: "#4F0336" }}>Uploaded</span>
                          <DownloadDoneIcon style={{ color: "#4F0336" }} />
                        </>
                      ) : (
                        <>
                          {uploading ? (
                            <span>
                              <Spinner />
                            </span>
                          ) : (
                            <>
                              <span>Upload</span>
                            </>
                          )}
                        </>
                      )}
                    </Button>
                    <span style={{ color: "#4F0336", fontSize: 12 }}>
                      *Address proof(s) once uploaded cannot be changed later.
                    </span>
                  </Stack>
                </>
              )}
              <DialogActions sx={dialogActionsStyles}>
                <>
                  <Button
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                    disabled={loading}
                    size="small"
                    sx={dialogCancelActionStyles}
                  >
                    Cancel
                  </Button>
                  <Button
                    //disabled={isDisabled}
                    type="submit"
                    size="small"
                    sx={dialogSubmitActionStyles}
                  >
                    {loading ? (
                      <span>
                        <Spinner />
                      </span>
                    ) : (
                      <span>Save</span>
                    )}
                  </Button>
                </>
              </DialogActions>
            </form>
          </DialogContent>
        </Box>
      </Dialog>
      <ErrorDialog
        open={openErrorDialog}
        close={() => setOpenErrorDialog(false)}
        message={errorMessage}
        isSuccess={false}
      />
    </>
  );
};

import React from 'react';
import { styled, keyframes } from '@mui/system';

// Define keyframes for the animation
const bounceAnimation = keyframes({
  '0%, 50%, 100%': {
    transform: 'scale(1)',
  },
  '25%': {
    transform: 'scale(0.6)',
  },
  '75%': {
    transform: 'scale(1.4)',
  },
});

// Styled component for the container
const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#fff',
});

// Styled component for the animated circle
const CircleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
});

const Circle = styled('div')({
  width: '8vmin', // Adjust size based on viewport width
  height: '8vmin', // Adjust size based on viewport width
  borderRadius: '50%',
  margin: '4vmin', // Adjust margin based on viewport width
  backgroundImage: 'linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%)',
  animation: `${bounceAnimation} 1.5s linear infinite`,  // Apply animation to the circle
});

// Styled component for text
const TextContainer = styled('div')({
  textAlign: 'center',
});

export const AnimatedCircles: React.FC = () => {
  return (
    <Container>
      <CircleContainer>
      <Circle style={{ backgroundColor: '#4f0336', animationDelay: '0s' }} />
        <Circle style={{ backgroundColor: '#f2eee5', animationDelay: '0.3s' }} />
        <Circle style={{ backgroundColor: '#7d3e58', animationDelay: '0.6s' }} />
        <Circle style={{ backgroundColor: '#f2eee5', animationDelay: '0.9s' }} />
      </CircleContainer>
      <TextContainer>
        <p>Waiting for payment confirmation...</p>
        <p>Please do not refresh or go back.</p>
      </TextContainer>
    </Container>
  );
};



import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { SavingScheme } from "../../components/select-account";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { TopHeader } from "../../components/top-header";
import { Typography } from "@mui/material";
import { PaymentForm } from "../../components/payment-form";
import { ProfileSetting } from "../../components/profile/ProfileSetting";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const OpenAccount: FC = () => {
  const navigate = useNavigate();
  const { currentUser, isLoggedIn } = useAuth();
  const [account, setAccount] = useState<string>('');
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [openProfileSettings, setOpenProfileSettings] = useState(false);
  const [schemeData, setSchemeData] = useState<any>();
  const isProfileCompleted = currentUser?.profileInfo?.userInfo?.firstName;


  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login?returnUrl=/open-account");
    } else if (currentUser) {
      const referenceNo = currentUser?.profileInfo?.referenceNo;
      // const accountsArr = new Array<string>();
      // accountsArr.push(referenceNo);
      // const subAccounts = currentUser?.profileInfo?.subUsers;
      // subAccounts.map((sa: any) => {
      //   accountsArr.push(sa.referenceNo);
      // });
      setAccount(referenceNo);
    }
  }, [currentUser, isLoggedIn]);

  // const getSubUserId = (referenceNo: string) => {
  //   const subAccounts = currentUser?.profileInfo?.subUsers;
  //   const filteredSubAccounts = subAccounts?.filter((item: any) => {
  //     return item.referenceNo === referenceNo;
  //   });
  //   return filteredSubAccounts[0]?.id ?? null;
  // };

  const makePayment = (schemeData: any) => {
    console.log(schemeData)
    const userScheme = {
      userId: currentUser?.profileInfo?.id,
      amount: schemeData?.amount,
      monthYear: schemeData.monthYear,
      createdBy: currentUser?.profileInfo?.id,
    };
    setSchemeData(userScheme);
    if (isProfileCompleted) {
      setOpenPaymentForm(true);
    } else {
      setOpenProfileSettings(true);
    }
  };

  return (
    <Box sx={{ pt: { xs: 7, sm: 8 } }}>
      <TopHeader title="Start Jewellery Plan" link="/scheme" />
      {result && result.message && (
        <Typography color={result.isSubmitted ? "primary" : "red"} margin={2}>
          {result.message}
        </Typography>
      )}
      <SavingScheme
        account={account}
        loading={loading}
        proceed={(event: any, tab: string, data: any) => {
          event?.preventDefault();
          makePayment({ ...data });
        }}
      />
      <PaymentForm
        open={openPaymentForm}
        onClose={() => setOpenPaymentForm(false)}
        schemeData={schemeData}
      />
      <ProfileSetting
        open={openProfileSettings}
        onClose={() => setOpenProfileSettings(false)}
      />
    </Box>
  );
};

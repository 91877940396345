import { Box, Typography } from "@mui/material"
import { FC } from "react"
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

interface TopHeaderProps {
    title: string;
    link?: string;
}

export const TopHeader: FC<TopHeaderProps> = ({ title, link }) => {
    const navigate = useNavigate();

    return (
        <Box>
            <Box
          width={"100%"}
          style={{ backgroundColor: "#4F0336", display: "flex" }}
          py={2}
          pl={2}
        >
            {link ? 
          <KeyboardArrowLeftIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigate(link)}
          /> : (
            <KeyboardArrowLeftIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />)}
          <Typography component="span" color={"white"} fontSize={16}>
            {title}
          </Typography>
        </Box>
        </Box>
    )
}
import { gql } from "@apollo/client";

export const CREATE_USER_SCHEME = gql`
    mutation CreateUserScheme($userSchemeInput: UserSchemeInput!) {
        createUserScheme(userSchemeInput: $userSchemeInput) {
            id
            isSuccess
            error
        }
    }
`;

export const GET_USER_SCHEME_BY_USER_ID = gql`
    query GetUserSchemeByUserId($userId: ID!) {
        getUserSchemeByUserId(userId: $userId) {
            id
            createdDate
            userId
            referenceNumber
            status
            payment {
                month
                year
                transactionId
                createdDate
            }
            user {
                phoneNo
                referenceNo
            }
            userInfo {
                firstName
                lastName
            }
        }
    }
`;
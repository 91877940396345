import { FC } from "react";
import {
  cardCommonStyles,
  customHeadingStyle,
  customBodyTextStyle,
} from "../../styles/card-text";
import { Box, Card, Typography } from "@mui/material";
import { parentBoxStyles } from "../../styles/box";

export const TnC: FC = () => {
  return (
    <Box pt={9}>
      <Box sx={parentBoxStyles}>
        <Box>
          <Typography
            variant="h1"
            fontSize={28}
            fontFamily={"ABC Gravity"}
            fontWeight={700}
            color="#4f0336"
            sx={{ pb: 3 }}>
            Terms & Conditions
          </Typography>
        </Box>
        <Box>
        <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customHeadingStyle }>
              Introduction
            </Typography>
            <Typography sx={customBodyTextStyle}>
              At Amaara Jewellery, we believe that everyone deserves to own
              beautiful and high-quality gold jewellery. That's why we offer a
              wide selection of gold jewellery at affordable prices, as well as
              a variety of saving schemes to help you make your purchase.
            </Typography>
          </Card>
        </Box>
        <Box>
          <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customHeadingStyle }>
              General
            </Typography>
            <Typography sx={customBodyTextStyle}>
              Welcome to Amaara Seetu, our exclusive Gold Savings scheme designed to help you achieve your dream jewellery pieces through convenient monthly instalments. By participating in Amaara Seetu, you agree to the following terms and conditions:
            </Typography>

            <Box >
              <Typography sx={{...customBodyTextStyle, paddingX: '20px', }}>
                &#8226; Scheme Duration: Amaara Seetu runs for up to 15 months, allowing you ample time to save for your desired jewellery.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Monthly Payment: To be eligible for the monthly raffle, you must pay GBP 100 by the 25th of each month.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Gold Price: The gold price for jewellery purchases will be determined on the day of purchase. We are not liable for any resulting losses.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Payment Flexibility: Customers can choose not to make a payment for any given month without penalty; however, their participation for that month will be skipped.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Account Closure: In rare cases, Seetu accounts may be closed with management confirmation. Deposits will be refunded in the event of extreme hardship or demise.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Refund Policy: Cash payments towards monthly deposits are non-refundable. Refunds are only available in the form of jewellery purchases.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Additional Bonus: An additional bonus of £100 is eligible for end-of-term collections, subject to conditions. Winners of raffles are not eligible for this bonus.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Redemption Limitations: Bullion, bars, or coins are generally not eligible for redemption at the end of the term, although exceptions may apply at management discretion.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Payment Responsibility: Customers paying by bank transfer are responsible for ensuring payments arrive before the cutoff time. We accept no liability for late payments or banking errors.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Late Payment Policy: Failure to pay before 00:00 midnight of the day before the raffle will void your entry for that specific month. Customers are responsible for timely payments, with exceptions considered under special circumstances.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Liability Disclaimer: K Gold Limited T/as Amaara Jewellery accepts no liability for losses resulting from changes in gold prices or scheme operations. Participants acknowledge that Amaara Seetu is not regulated by the FCA and agree to the terms and conditions outlined.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Data Protection: Customer KYC details will be held in accordance with GDPR laws for future reference.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Compliance: Participants must adhere to all scheme rules and regulations.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Changes to Terms: We reserve the right to change the terms and conditions of the scheme without prior notice.
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px',}}>
                &#8226; Complaints: Please forward all complaints to complaints@amaaraseetu.co.uk or info@amaarajewellery.co.uk.
              </Typography>
            </Box>

            <Typography sx={customBodyTextStyle}>
              By opening an account, you acknowledge that you have read and agreed to the terms and conditions outlined above. For any further queries, contact Amaara Jewellery at:
            </Typography>

            <Box>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Company Name: Amaara Jewellery (Trading name of K Gold Limited)
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Registration: Registered in England and Wales, Company Number: 12395568
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Address: 15 Ealing Road, Middlesex, HA0 4AA Telephone: 0207 460 6100
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; Email: info@amaarajewellery.co.uk
              </Typography>
              <Typography sx={{...customBodyTextStyle, paddingX: '20px'}}>
                &#8226; VAT Registration: 350495107
              </Typography>
            </Box>

            <Typography sx={customBodyTextStyle}>
              Please review these terms and conditions carefully before registering for Amaara Seetu. We strive to provide a transparent and rewarding experience for our customers.
            </Typography>
          </Card>
        </Box>


        <Box>
          <Card className="card" sx={cardCommonStyles}>
            <Typography sx={customHeadingStyle }>
              Cookies
            </Typography>
            <Typography variant="body1" sx={customBodyTextStyle}>
              A "cookie" is a small piece of information stored by a web server
              on a web browser so it can be later read back from that browser.
              No personal information will be collected via cookies and other
              tracking technology. However, if you previously provided
              personally identifiable information, cookies may be tied to such
              information. Aggregate cookie and tracking information may be
              shared with third parties.
            </Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

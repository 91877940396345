import { gql } from "@apollo/client";

export const GET_PAYMENT_FORM = gql`
    query GetPaymentForm($userSchemeInput: UserSchemeInput!) {
    getPaymentForm(userSchemeInput: $userSchemeInput)
}
`;

export const GET_PAYMENT_BY_TRANSACTION_ID = gql`
    query GetPaymentByTransactionId($transactionId: String!) {
        getPaymentByTransactionId(transactionId: $transactionId) {
            month
            year
            transactionId
            createdDate
        }
    }
`;
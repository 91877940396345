import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export const Notification: React.FC<any> = ({ notification }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#F2EEE5",
        borderRadius: "20px",
        border: "none",
        display: "flex",
        mt: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: 'none'
      }}
    >
      <CardContent>
        <Typography
          variant="body1"
          color="#777777"
          sx={{ fontSize: 12, margin: 2 }}
        >
          {notification.message}
        </Typography>

        <Typography
          variant="caption"
          color="#777777"
          sx={{ 
            fontSize: 10, 
            margin: 2, 
            fontStyle: 'italic',
            whiteSpace: 'nowrap', 
          }}
        >
          {notification.sentDate}
        </Typography>
      </CardContent>
      {/* Replace "url_to_your_image.jpg" with the actual URL or path to your image */}
      <Box
        component={"img"}
        src={require("../../images/amaaraOriginal.png")}
        alt="Notification Image"
        style={{ margin: 16, maxHeight: 85, maxWidth: 85 }}
      />
    </Card>
  );
};

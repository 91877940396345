import { CircularProgress } from "@mui/material";
import { FC } from "react";

export const Spinner: FC<any> = ({ style }) => {
  return (
    <CircularProgress
      sx={[{ color: "white" }, { ...style }]}
      style={{ width: 14, height: 14 }}
    />
  );
};

import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import {
  dialogActionsStyles,
  dialogCancelActionStyles,
  dialogContentStyles,
  dialogHeaderStyles,
  dialogSubmitActionStyles,
} from "../../styles/dialog";

interface ConfirmDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  message: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open = false,
  onClose,
  onConfirm,
  message,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={dialogHeaderStyles}>Confirm</DialogTitle>
      <Box>
        <DialogContent sx={dialogContentStyles}>
          <Box mb={2}>
            <Typography component="span" fontSize={14}>
              {message}
            </Typography>
          </Box>
          <DialogActions sx={dialogActionsStyles}>
            <>
              <Button
                onClick={onClose}
                size="small"
                sx={dialogCancelActionStyles}
              >
                Cancel
              </Button>
              <Button onClick={onConfirm} size="small" sx={dialogSubmitActionStyles}>
                <span>Confirm</span>
              </Button>
            </>
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

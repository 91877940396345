import { FC, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { buttonStyles } from "../../styles/button";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { parentBoxStyles } from "../../styles/box";
import { Spinner } from "../spinner";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type Input = {
  month: string;
  year: number;
  frequency: number;
};

type Output = {
  month: string;
  year: number;
};

const getMonthsAndYears = ({ month, year, frequency }: Input): Output[] => {
  let currentMonthIndex = monthNames.indexOf(month);

  if (currentMonthIndex === -1) {
    throw new Error("Invalid month name.");
  }

  if (frequency <= 0) {
    throw new Error("Frequency must be a positive number.");
  }
  let monthYear: Output[] = [];
  for (let i = 0; i < frequency; i++) {
    const monthYearObj = {
      month: monthNames[currentMonthIndex],
      year: year,
    };
    monthYear.push(monthYearObj);
    currentMonthIndex++;

    if (currentMonthIndex >= 12) {
      currentMonthIndex = 0;
      year++;
    }
  }

  return monthYear;
};

export const SavingScheme: FC<any> = ({ loading, proceed, account }) => {
  const navigate = useNavigate();
  const schemeBenefits = [
    "Flexible Payment Options",
    "Monthly Instalments",
    "Increased Purchasing Power",
    "Chance to Win Gold",
    "Additional Bonus",
    "Advance Design Selection",
    "Multiple Seetu Slots",
    "Exclusive Discounts",
  ];
  const [checked, setChecked] = useState(false);
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const monthYearArr = getMonthsAndYears({
    month: monthNames[currentMonth],
    year: currentYear,
    frequency: 3,
  });
  const [monthYearChecked, setMonthYearChecked] = useState([monthYearArr[0]]);
  const [amount, setAmount] = useState(100);
  const [selectedAccount, setSelectedAccount] = useState(account);

  const handleMonthCheck = (index: number) => {
    let updatedList: any = [];
    if (!monthYearChecked[index]) {
      for (let i = 0; i <= index; i++) {
        updatedList.push(monthYearArr[i]);
      }
    } else {
      updatedList = [...monthYearChecked];
      for (let j = monthYearChecked.length; j >= index; j--) {
        updatedList.splice(j, 1);
      }
    }
    const amount = 100 * updatedList.length;
    setAmount(amount);
    setMonthYearChecked(updatedList);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    proceed(event, "two", {
      amount: amount,
      monthYear: monthYearChecked,
      account: selectedAccount,
    });
  };

  return (
    <>
      {account && (
        <Box sx={parentBoxStyles} style={{ backgroundImage: "none" }}>
          <Box sx={styled.borderBox}>
            <Typography sx={{ fontSize: 18, color: "#4F0336" }}>
              Monthly Savings Scheme
            </Typography>
            <TextField
              fullWidth
              disabled={true}
              size="small"
              value={`Account: ${account}`}
              sx={{
                p: 2,
                input: { textAlign: "center" },
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "#000000",
                  WebkitTextFillColor: "#000000",
                },
              }}
            ></TextField>
            <Typography sx={[styled.text, { px: "4px" }]} component="span">
              You can select upto 3 months:
            </Typography>
            <Box sx={[styled.flex, { flexDirection: "row" }]}>
              {monthYearArr.map((monthYear: Output, index: number) => {
                return (
                  <Box key={index}>
                    <Checkbox
                      checked={!!monthYearChecked[index]}
                      onChange={() => handleMonthCheck(index)}
                      sx={{ color: "#CBAB58" }}
                    />
                    <Typography sx={styled.text} component="span">
                      {monthYear.month}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <TextField
              fullWidth
              disabled={true}
              size="small"
              value={`Amount: £${amount}`}
              sx={{
                p: 2,
                input: { textAlign: "center" },
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "#000000",
                  WebkitTextFillColor: "#000000",
                },
              }}
            ></TextField>
            <Box sx={styled.flex}>
              <CalendarMonthIcon sx={{ color: "#4F0336", fontSize: 18 }} />
              <Typography sx={[styled.text, { px: "4px" }]} component="span">
                Plan Duration:
              </Typography>
              <Typography
                sx={[styled.text, { color: "#4F0336" }]}
                component="span"
              >
                15 Months
              </Typography>
            </Box>
          </Box>
          <Box sx={styled.borderBox}>
            <Typography sx={{ fontSize: 18, color: "#4F0336" }}>
              Scheme Benefits
            </Typography>
            <Box sx={styled.flex}>
              <List>
                {schemeBenefits.map((benefit: string, index: number) => {
                  return (
                    <ListItem key={index}>
                      <Box sx={styled.flex}>
                        <DoubleArrowIcon
                          sx={{ color: "#CBAB58", fontSize: 18 }}
                        />
                        <Typography component="span" sx={styled.text}>
                          {benefit}
                        </Typography>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Box>
          <Box
            sx={[
              styled.flex,
              { mb: 1, width: "100%", flexDirection: "column" },
            ]}
          >
            <Box>
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                sx={{ color: "#CBAB58" }}
              />
              <Typography sx={styled.text} component="span">
                I agree to{" "}
              </Typography>
              <Typography
                component="span"
                sx={[
                  styled.text,
                  {
                    color: "#4F0336",
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                ]}
                onClick={() => navigate("/t&c")}
              >
                Terms and Condition
              </Typography>
            </Box>
            <Button
              sx={[
                buttonStyles,
                {
                  width: {
                    xs: "100%",
                    "&.Mui-disabled": {
                      background: "#F2EEE5",
                    },
                  },
                },
              ]}
              onClick={handleButtonClick}
              disabled={!amount || !checked}
            >
              {loading ? (
                <Typography>
                  <Spinner style={{ color: "#4F0336" }} />
                </Typography>
              ) : (
                <Typography textTransform="none">Proceed</Typography>
              )}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

const styled = {
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  borderBox: {
    border: "2px solid #CBAB58",
    borderRadius: "15px",
    margin: "8px 40px",
    textAlign: "center",
    width: "100%",
    py: 2,
  },
  text: {
    fontSize: 14,
    color: "#777777",
  },
};

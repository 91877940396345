import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CookieIcon from '@mui/icons-material/Cookie';
import { Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const dialogStyle: React.CSSProperties = {
  alignItems: 'center',
  textAlign: 'center',
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  maxWidth: '420px',
  padding: '0px',
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '10px',
  zIndex: '9999',
  boxShadow: '0px 6px 18px -5px rgba(79, 3, 54, 1)',
};

const mobileDialogStyle: React.CSSProperties = {
  ...dialogStyle,
  padding: 0,
  right: '0px',
  margin: '0px',
  borderLeft: '0',
  borderRight: '0',
  bottom: '0',
  borderRadius: '0px',
};

interface CookieConsentDialogProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const CookieConsentDialog: React.FC<CookieConsentDialogProps> = ({ open, onClose, onAccept }) => {
  const handleAccept = () => {
    document.cookie = "cookieBy=amaaraseetu; expires=" + new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toUTCString();
    localStorage.setItem('cookieAccepted', 'true');
    onAccept(); // Call the parent component's onAccept function
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: window.innerWidth <= 800 ? mobileDialogStyle : dialogStyle,
      }}
    >
      <DialogTitle style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', color: '#252525', fontWeight: 700, }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <CookieIcon style={{ color: '#4f0336', fontSize: 36 }} /> Cookies
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ fontSize: 18, textAlign: 'center', textWrap: 'pretty' }}>We use necessary cookies to ensure you get the best experience on our website. All cookies collect information in an anonymous form.</Typography>
      </DialogContent>
      <DialogActions style={{ width: "95%" }}>
        <Button onClick={handleAccept} style={{ width: '100%', backgroundColor: '#4f0336', color: '#fff', borderRadius: 5 }}>
          Accept
        </Button>
      </DialogActions>
      <Typography variant="body2" color="#252525" style={{ textAlign: 'center', marginTop: '10px', cursor: 'pointer', marginBottom: '10px' }} onClick={onClose}>Close</Typography>
    </Dialog>
  );
};



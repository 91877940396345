import { Box, List, ListItem, ListItemText, Button, Typography } from "@mui/material";
import { buttonStyles } from "../../styles/button";
import { useNavigate } from "react-router-dom";
import { Facebook, Instagram, InfoOutlined, PhoneCallbackOutlined } from "@mui/icons-material";

export const LargeScreenFooterComponent = () => {
  const navigate = useNavigate();

  const icons = [
    { src: Facebook, alt: 'Facebook', link: 'https://www.facebook.com/Amaaralondon' },
    { src: Instagram, alt: 'Instagram', link: 'https://www.instagram.com/amaarajewellery_london' },
  ];

  const listItems = [
    { src: '/Loc.svg', alt: 'SVG 2', text: 'Store Locations', link: '/store' },
    { src: '/Term.svg', alt: 'SVG 3', text: 'Terms and Conditions', link: '/t&c' },
    { src: InfoOutlined , alt: 'About Us', text: 'About Us', link: '/about-us' }, 
    { src: PhoneCallbackOutlined, alt: 'Contact Us', text: 'Contact Us', link: '/contact-us' }, 
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: "#f2eee5",
        width: "100vw",
        paddingX: 4,
        paddingY: 8
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={require('../../images/amaaraOriginal.png')}
          alt="Image Alt Text"
          style={{ width: "20em", height: "10em", marginBottom: "8px" }}
        />

        <List
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "8px",
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {icons.map((icon, index) => (
            <ListItem key={index} onClick={() => window.open(icon.link, '_blank')} sx={{ cursor: 'pointer', paddingX: 1 }}>
              <icon.src style={{ color: "#4F0336", fontSize: 22 }} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <List>
          {listItems.map((item, index) => (
            <ListItem key={index} sx={{ lineHeight: "1.2", cursor: 'pointer' }} onClick={() => navigate(item.link)}>
              {typeof item.src === 'string' ? (
                <img
                  src={item.src}
                  alt={item.alt}
                  style={{ width: "30px", height: "30px", marginRight: "8px" }}
                />
              ) : (
                <item.src style={{ color: "#4F0336", width: "30px", height: "30px", marginRight: "8px" }} />
              )}
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  variant: "body2",
                  fontSize: "14px",
                  color: "#4F0336",
                }}
                sx={{ marginLeft: "8px" }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

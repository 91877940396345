import React from 'react';
import './Loader.css'; 

export const Loader: React.FC = () => {
  return (
    <div
      className="loader"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)" 
      }}
    >
      <div className="loader-inner"></div>
    </div>
  );
}



import { FC, useState } from "react";
import {
  TextField,
  Button,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  ImageList,
  ImageListItem,
} from "@mui/material";
import {
  dialogActionsStyles,
  dialogCancelActionStyles,
  dialogContentStyles,
  dialogHeaderStyles,
  dialogSubmitActionStyles,
} from "../../styles/dialog";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_USER_INFO } from "../../gql/user-info";
import { useAuth } from "../../contexts/AuthContext";
import { Spinner } from "../spinner";
import { storage } from "../../config/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { useEffect } from "react";
import { GET_PAYMENT_FORM } from "../../gql/payment";
import CloseIcon from "@mui/icons-material/Close";

interface PaymentFormProps {
  open: boolean;
  onClose: () => void;
  schemeData: any;
}

const getSelectedMonths = (monthYearArr: any) => {
  const months: string[] = [];
  for (let i=0; i< monthYearArr?.length; i++) {
    months.push(monthYearArr[i].month);
  }
  return months;
}

export const PaymentForm: FC<PaymentFormProps> = ({
  open = false,
  onClose,
  schemeData,
}) => {
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const { data, loading } = useQuery(GET_PAYMENT_FORM, {
    variables: {
      userSchemeInput: schemeData,
    },
  });
  const paymentForm = data?.getPaymentForm;
  const selectedMonths: string[] = getSelectedMonths(schemeData?.monthYear);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={styled.header}>
        <Typography>Payment Details</Typography>
        <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
      </DialogTitle>
      <Box>
        <DialogContent sx={dialogContentStyles}>
          {result && result.message && (
            <Typography
              color={result.isSubmitted ? "#4F0336" : "red"}
              mb={2}
              fontSize={12}
            >
              {result.message}
            </Typography>
          )}
          <Box>
            <Box component="img" src="/payment.svg" height={100} />
            <Box pb={2}>
              <Typography>
                Please take a moment to review your payment details carefully
                before proceeding with your transaction. Your satisfaction is
                our priority, and ensuring accuracy in your payment information
                helps us serve you better.
              </Typography>
            </Box>
            <Box sx={styled.content}>
              <Typography>Month Selected:</Typography>
              <Typography color="black" fontWeight="bold">
                {selectedMonths?.toString()}
              </Typography>
            </Box>
            <Box sx={styled.content} pb={2}>
              <Typography>Total Amount:</Typography>
              <Typography color="black" fontWeight="bold">
                £{schemeData?.amount}
              </Typography>
            </Box>
          </Box>
          {loading ? (
            <Box>
              <Spinner style={{ color: '#4f0336' }} />
            </Box>
          ) : (
            <div
              className="Container"
              dangerouslySetInnerHTML={{ __html: paymentForm }}
            ></div>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

const styled = {
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingX: "12px",
    paddingY: 2,
    background: "#4F0336",
    textAlign: "center",
    color: "white",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: 2,
  },
};

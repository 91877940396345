import { FC } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import {
  cardCommonStyles,
  customHeadingStyle,
  customBodyTextStyle,
} from "../../styles/card-text";
import { TeaserCarousel } from "../../components/teaser-carousel";
import { buttonStyles } from "../../styles/button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";


export const Scheme: FC = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const images = ["/schemeCarousel 1.png", "/schemeCarousel 2.png", "/schemeCarousel 3.png"];
  const goToOpenAccount = () => {
    if (!isLoggedIn) navigate('/login?returnUrl=/open-account');
    else navigate('/open-account')
  };

  return (
    <Box pt={7} pb={4}>
      <Box onClick={goToOpenAccount} style={{ cursor: 'pointer' }}>
        <TeaserCarousel images={images} />
      </Box>
      <Box>
        <Box
          component="img"
          src="/seetuPage.png"
          alt="seetu scheme"
          width="100%"
          display={{ xs: "none", sm: "block" }}
        />
        <Box
          component="img"
          src="/seetuPageMobile.png"
          alt="seetu scheme"
          width="100%"
          display={{ xs: "block", sm: "none" }}
        />
      </Box>
      <Box
        sx={{
          width: "100vw",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "inline-flex",
          py: 2,
          paddingX: { xs: 3, sm: "20%", md: "25%" },
        }}
      >
        <Card className="card" sx={[cardCommonStyles, { mb: 0 }]}>
          <Typography sx={customHeadingStyle}>About Scheme</Typography>
          <Typography sx={customBodyTextStyle}>
            Amaara Seetu, our exclusive gold savings scheme designed to make owning exquisite jewellery a reality.
            With Seetu, you can save for your favourite jewellery pieces through easy monthly instalments, tailored
            to fit your budget and lifestyle. It's a hassle-free way to gradually build up your savings while indulging
            in the joy of acquiring stunning gold jewellery. Plus, with monthly raffles offering the chance to win gold
            worth up to £1500, there's an added element of excitement to your saving journey. Join Amaara Seetu today
            and embark on a rewarding path towards owning the jewellery of your dreams.
          </Typography>
          <Typography sx={{ ...customBodyTextStyle, marginY: '8px', color:'#4f0336' }} fontWeight={700}>
            Benefits of Amaara Seetu Scheme:
          </Typography>

          <Box >
            <Typography sx={customBodyTextStyle}>
              &#8226; Flexible Payment Options: Pay when it suits you best, without any pressure or strict deadlines.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Monthly Instalments: Spread the cost of your jewellery over time with manageable monthly payments, making it easier on your budget.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Increased Purchasing Power: Build up your savings gradually and buy more gold jewellery with the money you've saved.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Chance to Win Gold: Take part in monthly raffles for a shot at winning gold worth up to £1500, adding some excitement to your savings plan.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Additional Bonus: Get an extra £100 bonus towards your savings when you finish the scheme, as our way of saying thanks for saving with us.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Advance Design Selection: Plan ahead and choose your jewellery designs in advance, so we can make sure we have exactly what you want when you're ready to buy.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Multiple Seetu Slots: Save for different things or people at the same time by having more than one Seetu account.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Exclusive Discounts: Enjoy special discounts on jewellery purchases during special occasions, giving you even more value for your money as a loyal Seetu customer.
            </Typography>
          </Box>

          <Typography sx={{ ...customBodyTextStyle, marginY: '8px', color:'#4f0336' }} fontWeight={700}>
            Eligibility Criteria for Amaara Seetu Scheme:
          </Typography>
          <Box>
            <Typography sx={customBodyTextStyle}>
              &#8226; Age Requirement: Participants must be at least 18 years old to join the scheme.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Residency: While we recommend participants to be residents of the UK, the scheme is open to individuals residing in any country.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Identification: Valid identification documents such as a government-issued ID card, passport, or driver's licence are required for registration.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Address Proof: Participants must provide proof of address, which can include utility bills, rental agreements, or official correspondence.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Registration: Customers need to register directly with us to obtain a unique Seetu Reference number for making payments.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Nominee Authorization: A nominated individual, such as a next of kin or relative, must be authorized for emergency or unforeseen circumstances.
            </Typography>
            <Typography sx={customBodyTextStyle}>
              &#8226; Compliance: Participants are expected to comply with all rules and regulations outlined in the scheme's terms and conditions.
            </Typography>
          </Box>
          <Typography sx={{ ...customBodyTextStyle, marginY: '8px', color:'#4f0336' }} fontWeight={700}>
            Participation Rules for Amaara Seetu Scheme:
          </Typography>
          <Typography sx={customBodyTextStyle}>
            <Box>
              <Typography sx={customBodyTextStyle}>
                &#8226; Monthly Payment Deadline: Customers are required to make a payment of GBP 100 by 10 am on the 25th of each month to qualify for the raffle of that particular month. Any payments received after the cutoff time will not be accepted.
              </Typography>
              <Typography sx={customBodyTextStyle}>
                &#8226; Scheme Duration: The scheme runs for a duration of 15 months from the start date.
              </Typography>
              <Typography sx={customBodyTextStyle}>
                &#8226; Deposit Process: Under this scheme, individuals can make payments either by cash at our retail shop located at 15 Ealing Road, HA0 4AA, or by bank transfer to our authorised bank accounts provided at the time of registration. Customers are responsible for collecting payment receipts as proof of payment.
              </Typography>
              <Typography sx={customBodyTextStyle}>
                &#8226; Tuesday Closure Exception: If the 25th falls on a Tuesday (when our shop is closed), the raffle will be held on the next opening day.
              </Typography>
              <Typography sx={customBodyTextStyle}>
                &#8226; Late Payment Policy: Customers must ensure payments are made on time to avoid voiding their raffle entry for that specific month. Late payments may be considered under special circumstances only if confirmed directly with us.
              </Typography>
            </Box>
          </Typography>

        </Card>
      </Box>
      <Box
        sx={[
          styled.flex,
          { mt: 2, width: "100%", px: { xs: 6, sm: "20%", md: "25%" } },
        ]}
      >
        <Button
          sx={[buttonStyles, { width: { xs: "100%" } }]}
          onClick={goToOpenAccount}
        >
          Open New Account
        </Button>
      </Box>
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    justifyContent: "center",
  },
};

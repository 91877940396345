import React, { FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { Receipt } from "../receipt";

interface Payment {
  month: string;
  year: number;
  transactionId: string;
  createdDate: string;
}

interface PaymentTableProps {
  payments: [Payment];
  user: any;
  schemeRef: number;
}

export const PaymentTable: FC<PaymentTableProps> = ({ payments, user, schemeRef }) => {
  console.log(payments);
  return (
    <Grid container spacing={1} justifyContent="center">
      {payments?.map((item, index) => (
        <Grid item xs={12} key={index} sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Card sx={{
            width: '90%',
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            border: "1px solid #4f0336",
            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography variant="h6" color="#4f0336" gutterBottom>
                Transaction Details
              </Typography>
              <Typography variant="body2" color="textSecondary">
                EMI Number: {index + 1}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Transaction Id: {item.transactionId}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                EMI Month: {item.month}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                EMI Year: {item.year}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Paid on: {item.createdDate}
              </Typography>
            </CardContent>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              order: { xs: 2, sm: 1 },
            }}>
              <Receipt payment={item} user={user} index={index} schemeRef={schemeRef} />
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

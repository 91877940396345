import { FC, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import {
  dialogActionsStyles,
  dialogCancelActionStyles,
  dialogContentStyles,
  dialogHeaderStyles,
  dialogSubmitActionStyles,
} from "../../styles/dialog";
import { useReactToPrint } from "react-to-print";
import { isDesktop } from 'react-device-detect';
import generatePDF, { Margin } from 'react-to-pdf';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

interface ConfirmDialogProps {
  open: boolean;
  onClose?: () => void;
  user: any;
  payment: any;
  index: number;
  schemeRef: number;
}

export const ReceiptDialog: FC<ConfirmDialogProps> = ({
  open = false,
  onClose,
  user,
  payment,
  index,
  schemeRef,
}) => {
    let componentRef = useRef(null);
    const getTargetElement = () => document.getElementById('receipt-id');
  
    const handlePrint = useReactToPrint({
      documentTitle: "Amaara Jewellery Receipt",
      onBeforePrint: () => console.log("before printing..."),
      onAfterPrint: () => console.log("after printing..."),
      removeAfterPrint: true,
    });
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={dialogHeaderStyles}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <span>Receipt</span>
          <ReceiptLongIcon/>
        </Stack>
        </DialogTitle>
      <Box>
        <DialogContent sx={dialogContentStyles}>
          <Box mb={2}>
          <div style={{ width: 250 }}>
          <Box ref={componentRef} sx={styled.flex} m={2} id="receipt-id">
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={styled.boldText}>Amaara Jewellery</Typography>
              <Typography sx={styled.boldText}>15 Ealing Road</Typography>
              <Typography sx={styled.boldText}>
                Specialist in 22Kt Gold
              </Typography>
              <Typography sx={styled.boldText}>Jewellery</Typography>
              <Typography sx={styled.boldText}>Wembley HA0 4AA</Typography>
              <Typography sx={styled.boldText}>0207 460 6100</Typography>
            </Box>
            <Box>
            <Typography sx={styled.normalText}>
                Cust ID: {user.referenceNo}
              </Typography>
              <Typography sx={styled.normalText}>
                Customer: {user.firstName} {user.lastName}
              </Typography>
              <Typography sx={styled.normalText}>
                Telephone: {user.phoneNo}
              </Typography>
              <Typography sx={styled.normalText}>
                Seetu Number: {schemeRef}
              </Typography>
              <Typography sx={styled.normalText}>
                EMI Number: {index + 1}
              </Typography>
              <Typography sx={styled.normalText}>
                EMI Month: {payment.month}
              </Typography>
              <Typography sx={styled.normalText}>
                EMI Year: {payment.year}
              </Typography>
              <Typography sx={styled.normalText}>
                Paid On: {payment.createdDate}
              </Typography>
            </Box>
            <br />
            <Box sx={styled.spacedFlex}>
              <Typography sx={styled.normalText}>
                Invoice Ref
              </Typography>
              <Typography sx={styled.normalText}>
                Paid Value
              </Typography>
            </Box>
            <Box sx={styled.spacedFlex}>
              <Typography sx={styled.normalText}>
                {payment.transactionId}
              </Typography>
              <Typography sx={styled.normalText}>
                £100.00
              </Typography>
            </Box>
            <Box sx={styled.spacedFlex}>
              <Typography sx={styled.normalText} fontWeight={'bold'}>
                Total Paid:
              </Typography>
              <Typography sx={styled.normalText} fontWeight={'bold'}>
                £100.00
              </Typography>
            </Box>
            <Box sx={styled.spacedFlex}>
              <Typography sx={styled.normalText}>
                Paid {payment.transactionId?.includes('S') ? 'By Cash' : 'Online'}
              </Typography>
              <Typography sx={styled.normalText}>
                £100.00
              </Typography>
            </Box>
            <Box sx={styled.spacedFlex}>
              <Typography sx={styled.normalText}>
                Change
              </Typography>
              <Typography sx={styled.normalText}>
                £0.00
              </Typography>
            </Box>
          </Box>
        </div>
          </Box>
          <DialogActions sx={dialogActionsStyles}>
            <>
              {onClose && (
                <Button
                  onClick={onClose}
                  size="small"
                  sx={dialogCancelActionStyles}
                >
                  Close
                </Button>
              )}
              <Button
                size="small"
                sx={dialogSubmitActionStyles}
                onClick={() => {
                    if (isDesktop) {
                      handlePrint(null, () => componentRef.current);
                    } else {
                      generatePDF(getTargetElement, {
                        filename: "Amaara Jewellery Receipt.pdf",
                        page: { margin: Margin.LARGE },
                      });
                    }
                  }}
              >
                <span>Print</span>
              </Button>
            </>
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

const styled = {
    flex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    spacedFlex: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    boldText: {
      fontWeight: "600",
      textTransform: "none",
      fontSize: 18,
    },
    normalText: {
      textTransform: "none",
      fontSize: 14,
    },
  };
  
import { Box, Grid } from "@mui/material";
import { FC } from "react";

interface IPromotionProps {
  promotions: []
}

export const Promotion: FC<IPromotionProps> = ({ promotions }) => {

  return (
    <Box>
      <Grid container sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        {promotions &&
          promotions.map((promotion: any, index: number) => {
            return (
              <Grid item key={index} display={"contents"}>
                <Box
                  component="img"
                  src={promotion.image}
                  alt={promotion.type}
                  sx={{ width: { xs: "100%", sm: "50%"}, height: { xs: "200px", sm: "auto"}, padding: { xs: 0, sm: 1 } }}
                  // onClick={() => navigate(promotion.link)}
                ></Box>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
